import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import styled from 'styled-components';
import {white} from '../../styles/globalStyleVars';
import reactHtmlParser from 'react-html-parser';
import Subtitle from '../nziuac-title/Subtitle';

/**
 * Overview Type 6 component section
 * Pass the props with your data to the Overview Type 6 component.
 * Check the page folder to see the components used.
 **/
const ParternsList = ({data, id, offset}) => {


  return (
    <StyledOverview6 id={id} offset={offset} className={'ministry '}>
      <Container>
        <Row>
          <Col md={12}>
            <Subtitle text={data?.section_data?.subtitle ? reactHtmlParser(data?.section_data?.subtitle) : 'Subtitle Here'} color={'rgba(4,5,4,0.3)'} fontSize={16} fontWeight={400} lineHeight={20} margin={'0 0 60px'}/>
            
            <h2 className={'split-up'}>
              {
                data?.section_data?.short_desc ?
                  reactHtmlParser(data?.section_data?.short_desc) : ''
              }

            </h2>
          </Col>

        </Row>
        <Row className={'versity-list-row'}>


          {
            data?.images?.list && data?.images?.list?.length > 0 &&
              data?.images?.list?.map((item , index) => {
                console.log(item?.thumb);
                return(
                  <Col key={index}>
                    <a href={item?.short_desc} target={'_blank'} rel="noreferrer">
                      <div className="image-wrapper">

                        <img className={`${item?.thumb ? 'filter' : ''}`} height={58} width={82} src={item?.full_path} alt={item?.short_title}/>
                        <p className={'split-up'}>{item?.short_title ? item?.short_title : 'Title'}</p>
                      </div>
                    </a>
                  </Col>
                );
              })
          }
        </Row>
      </Container>
    </StyledOverview6>
  );
};

/**
 * Styled component for Overview Type 6 component section
 * Add your custom styles for this component
 **/
const StyledOverview6 = styled.section`
    position: relative;
    padding-top: 210px;
    padding-bottom: 160px;

    h2 {
        margin-bottom: 40px;
    }

  .filter{
    filter: invert(1);
  }

    .versity-list-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: -15px; /* Adjust for negative margins */

        .col {
            flex: 0 0 calc(16.666% - 30px); /* 16.666% to accommodate the gap */
            max-width: calc(16.666% - 30px); /* 16.666% to accommodate the gap */
            margin: 15px; /* Adjust for negative margins */
            padding-left: 0;
            padding-right: 0;

            .image-wrapper {
                background: ${white};
                padding: 45px 15px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
              text-align: center;

                a {
                    position: absolute;
                    inset: 0;
                    z-index: 1;
                }

                img {
                    transition: 0.7s all ease;
                    position: relative;
                }

                p {
                    position: absolute;
                    transform: translateY(50px);
                    transition: 0.7s all ease;
                    opacity: 0;
                    visibility: hidden;
                  text-align: center;
                  width: calc(100% - 15px);
                  margin: 0 auto;
                }

                &:hover {
                    p {
                        transform: translateY(0);
                        opacity: 1;
                        visibility: visible;

                    }

                    img {
                        transform: translateY(50px);
                        opacity: 0;
                        visibility: hidden;

                    }
                }
            }

            @media (max-width: 1024px) and (min-width: 768px) {
              flex: 0 0 calc(33.33333% - 30px);
              max-width: calc(33.33333% - 30px);
            }
            @media (max-width: 767px) {
                flex: 0 0 calc(50% - 30px); /* 16.666% to accommodate the gap */
                max-width: calc(50% - 30px); /* 16.666% to accommodate the gap */


            }
        }
    }

`;

export default React.memo(ParternsList);
