import {createGlobalStyle, css} from 'styled-components';
import {gray, hover, softGray, text, title, white} from './globalStyleVars';

function createCSS() {
  let styles = '';

  for (let i = 2; i < 20; i += 1) {
    styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `;
  }

  for (let a = 2; a < 100; a += 1) {
    styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `;
  }

  return css`${styles}`;
}

export default createGlobalStyle`

    ${createCSS()}
    #root {
        min-height: 100vh;
        overflow-x: hidden;
    }


    @font-face {
        font-family: 'Graphik';
        src: url('/fonts/Graphik-Semibold.woff2') format('woff2'),
        url('/fonts/Graphik-Semibold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Graphik';
        src: url('/fonts/Graphik-Medium.woff2') format('woff2'),
        url('/fonts/Graphik-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Graphik';
        src: url('/fonts/Graphik-Regular.woff2') format('woff2'),
        url('/fonts/Graphik-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Graphik';
        src: url('/fonts/Graphik-Light.woff2') format('woff2'),
        url('/fonts/Graphik-Light.woff2') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }


    :root {
        font-size: 15px; /* You can adjust this to your base font size */
        letter-spacing: 0;
        line-height: 20px;
    }




    .hide{
        opacity: 0;
        visibility: hidden;
        display: none;
    }
    //custom scrollbar 
    html {
        /* width */

        ::-webkit-scrollbar {
            width: 5px;
            display: none;
        }

        /* Track */

        ::-webkit-scrollbar-track {
            background: transparent;
            display: none;

        }

        /* Handle */

        ::-webkit-scrollbar-thumb {
            background: ${gray};
            display: none;
            
        }

        /* Handle on hover */

        ::-webkit-scrollbar-thumb:hover {
            //background: transparent;
        }
    }



   
    body {
        scroll-behavior: smooth;
        text-rendering: optimizeSpeed;
        font-family: Graphik, Arial, Helvetica, freesans, sans-serif;
        font-style: normal;
        margin: 0;
        color: ${text};
        padding: 0;
        overflow-x: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 100vh;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px; /* 20px / 15px = 1.3333rem */
        position: relative;
        /* Hide scrollbar for Chrome, Safari and Opera */
        //overscroll-behavior: none;
        // pointer-events: none;
        
        @media(min-width: 1024px){
            -webkit-text-size-adjust: 100%;
            -moz-text-size-adjust: 100%;
            text-size-adjust: 100%;
            overscroll-behavior: none;
        }

     
        &.mobile-menu-open {
            overflow: hidden !important;
        }


        &.stop-scroll {
            //overflow: hidden;
        }

        background: #FFFFFF;

        
        @media(max-width: 767px){
            overflow-y: scroll;
        }
        
        
        

    }

    //Typograpphy
    h1 {
        font-size: 80px;
        font-weight: 600;
        line-height: 90px;
        color: ${text};
        @media (max-width: 767px) {
            font-size: 40px !important;
            line-height: 40px !important;
        }
    }
    .h1 {
        font-size: 80px;
        font-weight: 600;
        line-height: 90px;
        color: ${text};
        @media (max-width: 767px) {
            font-size: 40px !important;
            line-height: 40px !important;
        }
    }

    h2 {
        font-size: 40px;
        font-weight: 500;
        line-height: 45px;
        color: ${text};
        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 32px;
        }

        span {
            font-weight: bold;
            color: ${hover}
        }
    }

    h3 {
        font-size: 32px;
        font-weight: 500;
        line-height: 38px;
        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 36px;
        }
    }


    h4 {
        font-size: 28px;
        font-weight: 400;
        line-height: 32px;
        &.mid{
            font-weight:  500 !important ;
        }
    }


    h5 {
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        &.mid{
            font-weight: 500 !important;
        }
    }

    h6 {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        &.mid{
            font-weight:  500 !important;
        }
    }


    .meta-data {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    .menu-data {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }

    .buttons-data {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }


    .footer-data {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }

    .versity-name {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }


    ul {
        margin: 0;
        padding: 0
    }

    li {
        list-style: none;
    }


    .nzuac-list {
        margin: 60px 0;

        li {
            position: relative;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(241, 240, 238, 0.2);
            padding-left: 28px;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: ${text};

            &:after {
                position: absolute;
                left: 0;
                top: 5px;
                content: '';
                background: ${hover};
                height: 8px;
                width: 8px;
                border-radius: 50%;
            }

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }
    .nzuac-list-count {
        margin: 60px 0;
        counter-reset: listItem 0;
        li {
            position: relative;
            padding-bottom: 20px;
            margin-bottom: 20px;
            padding-left: 45px;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: ${text};

            &:before{
                content: counter(listItem);
                counter-increment: listItem 1;
                position: absolute;
                left: 0;
                top: 16px;
                margin-top: -15px;
                display: flex;
                align-items: flex-start;
                box-sizing: content-box;
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                color: white;
                z-index: 1;
                height: 24px;
                width: 24px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &:after {
                position: absolute;
                left: 0;
                top: 0;
                content: '';
                background: ${hover};
                height: 24px;
                width: 24px;
                border-radius: 50%;
            }

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }





    .scroll-down.desktop-menu{
        transform: translate3d(0, -100%, 0) !important;
    }
    .scroll-up.desktop-menu {
        transform: translate3d(0, 0, 0) !important;
    }





    .scroll-down .Mobile-menu-wrap{
        transform: translate3d(0, -100%, 0) !important;
    }
    .scroll-up .Mobile-menu-wrap {
        transform: translate3d(0, 0, 0) !important;
    }






    blockquote {
        display: block;
        border-left: 4px solid #C9B385;
        padding-left: 20px;

        span {
            display: block;
            margin-top: 40px;
        }
    }


    //table
    table {
        gap: 20px;

        th {
            font-size: 1.3333rem; /* 20px / 15px = 1.3333rem */
            font-weight: 300;
            line-height: 1.8667rem; /* 28px / 15px = 1.8667rem */
            color: #181D24;
            border-top: none !important;
        }

        tr {
            border-bottom: 1px solid rgba(241, 240, 238, 0.2);
            justify-content: flex-start;
            padding: 20px 0;
            display: flex;
            flex-wrap: wrap;
            gap: 30px;

            &:first-child {
                border-bottom: 1px solid rgba(241, 240, 238, 1);
            }

            &:last-child {
                border-bottom: none;
            }

            th {
                max-width: calc(50% - 30px);
                flex: 0 0 calc(50% - 30px);
            }

            td {
                max-width: calc(50% - 30px);
                flex: 0 0 calc(50% - 30px);
            }
        }
    }


    //typography responsive


    a {
        transition: color .3s cubic-bezier(.54, .32, .05, .96);
        text-decoration: none;
        color: ${text};

        &:hover {
            color: ${text};
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }

        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
            color: ${text};
        }
    }

    ::selection {
        background: ${hover};
        color: #FFF;
    }


    h1, h2, p, a, h4, h5, h6 {
        margin: 0;
    }


    img {
        max-width: 100%;
        object-fit: contain;
    }


    .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
        outline: none;
        box-shadow: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid rgba(0, 0, 0, 0);
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
        transition: background-color 5000s cubic-bezier(.54, .32, .05, .96) 0s;
    }


    table {
        width: 100%;
    }

    form div {
        position: relative;
    }

    .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;

        &:focus {
            box-shadow: none;
        }
    }

    .p-0 {
        padding: 0 !important;
    }

    .pl-0 {
        padding-left: 0;
    }

    .pr-0 {
        padding-right: 0;
    }

    .pt-200 {
        padding-top: 200px;
        @media (max-width: 767px) {
            padding-top: 120px;
        }
    }

    .pb-200 {
        padding-bottom: 200px;
        @media (max-width: 767px) {
            padding-bottom: 120px;
        }
    }

    .pt-160 {
        padding-top: 160px;
        @media (max-width: 767px) {
            padding-top: 60px;
        }
    }

    .pb-160 {
        padding-bottom: 160px;
        @media (max-width: 767px) {
            padding-bottom: 120px;
        }
    }

    .pb-130 {
        padding-bottom: 130px;
        @media (max-width: 767px) {
            padding-bottom: 120px;
        }
    }

    .pt-100 {
        padding-top: 100px;
        @media (max-width: 767px) {
            padding-top: 60px;
        }
    }
    
    .mt-100 {
        margin-top: 100px;
        @media (max-width: 767px) {
            margin-top: 60px;
        }
    }

    .pb-100 {
        padding-bottom: 100px;
        @media (max-width: 767px) {
            padding-bottom: 60px;
        }
    }

    .pt-80 {
        padding-top: 80px;
        @media (max-width: 767px) {
            padding-top: 40px;
        }
    }

    .pb-80 {
        padding-bottom: 80px;
        @media (max-width: 767px) {
            padding-bottom: 40px;
        }
    }


    .MuiDrawer-paper {
        width: 500px !important;
        padding: 20px;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    .swiper-button-disabled {
        opacity: 0.3 !important;
    }

    @media (min-width: 1500px) {
        .container {
            //min-width: 1366px;
            min-width: 85%;
            margin: auto;
        }
    }

    @media (max-width: 1199px) and (min-width: 768px) {
        .container, .container-lg, .container-md, .container-sm {
            max-width: 90%;
            margin: auto;
        }
    }


    @media (max-width: 767px) {
        .container, .container-sm {
            max-width: 100%;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    
    // fixed background
    .fixed-bg{
        position: absolute;
    }
    

    //react select
    .css-yk16xz-control, .css-1pahdxg-control {
        height: 50px;
        border-radius: 0 !important;
        padding-left: 5px;
        font-size: 16px;
        outline: none !important;
        border-color: #D9D9D9 !important;
        box-shadow: none !important;

        .css-1wa3eu0-placeholder {
            font-weight: 300;
            line-height: 21px;
            color: rgba(0, 0, 0, 0.5);
            outline: none;
        }

        .css-1okebmr-indicatorSeparator {
            display: none;
        }

        .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
            margin-right: 10px;
        }
    }

    .css-2613qy-menu {
        border-radius: 0 !important;
        margin-top: 0 !important;
    }

    //animation class


    .info-window {
        max-width: 200px;
    }

    .gm-style-iw {
        border-radius: 0 !important;
    }

    .swiper-pagination-bullet {
        outline: none;
    }

    .css-nmuc1a-menu {
        z-index: 5 !important;
    }


    .map-info__img {
        img {
            height: 100px;
            margin-bottom: 12px;
            object-fit: cover;
        }
    }

    .map-info__content {
        h4 {
            font-size: 20px;
        }

        p {
            margin-bottom: 5px;
        }
    }

    .overlay {
        position: fixed;
        height: 100vh;
        width: 100%;
        //background-color: rgba(0,0,0,0.5);
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 9;
        display: none;

        &.show {
            display: block;
        }
    }

    .form-control.has-error {
        border-color: #dc004e !important;
    }

    .has-error .find-retainer-filter__control {
        border-color: #dc004e !important;
    }

    //preloader
    .content-loader {
        position: absolute;
        height: 70%;
        width: 70%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .loading-before-submit {
        position: fixed;
        height: 100vh;
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.65);
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 40px;
        }
    }


    .swiper-slide {
        height: auto;
    }

    .slick-slide {
        div {
            outline: none !important
        }
    }

    button, button:active, button:focus, button:focus-visible {
        outline: none !important;
        box-shadow: none !important;
    }


    .hover {
        position: relative;
        overflow: hidden;

        span {
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: ${hover};
            transition: all .5s cubic-bezier(.54, .32, .05, .96);
            border-radius: 19px;
        }

        &:hover {
            &:after {
                height: 100%;
                width: 100%;
            }
        }
    }


    .modal-backdrop {
        background-color: rgb(34 31 31 / 90%);
        min-width: 100%;
        //z-index: 9999;

        &.show {
            opacity: 1;
        }
    }


    .valid {
        color: ${hover};
        position: absolute;
        font-size: 12px;
        top: 44px;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }


    //menu fixed
    .scroll-down .menu-desktop {
        transform: translate3d(0, -100%, 0);
    }

    .scroll-down .scroll_to {

    }

    .scroll-up.scroll_to {
        //margin-top: 100px !important;

        .container {
            position: relative;
        }
    }

    .mobile-menu-open {
        .scroll-down.main-menu {
            transform: translate3d(0, 0, 0) !important;
        }

        .border-container {
            &:after {
                display: none;
            }
        }

        .scroll-up.main-menu {
            transform: translate3d(0, 0, 0) !important;
        }
    }


    .scroll-up .menu-desktop {
        background-color: #fff;

        .menu-desktop__hamburger__lines {
            &__inner .line {
                background-color: #191818;
            }

            p {
                color: #191818;
            }
        }

        .menu-desktop__hamburger .controller svg {
            path {
                fill: #191818;
            }

            line {
                stroke: #191818;
            }
        }

        .dc-btn a {
            color: #191818;

            &:hover {
                color: #191818 !important;
            }

            &:after, &:before {
                box-shadow: 0 0 0 1px #191818;
            }
        }
    }


    .scroll-up-mb.main-menu-mobile {
        background: #FFFFFF;
        transform: translate3d(0, 0, 0) !important;

        .normal {
            display: none;
        }

        .sticky {
            display: block;
        }

        #Rectangle_5469 {
            fill: ${softGray};
        }

        .search-icon-mobile {
            svg {
                path {
                    fill: ${text};
                }
            }
        }
    }

    .main-menu-mobile.scroll-down-mb {
        transform: translate3d(0, -100%, 0);

    }


    .form-control:disabled {
        background-color: transparent;
    }

    @media (max-width: 600px) {
        .prevent-overflow {
            overflow: hidden;
            height: 100vh;
        }
    }

    .Toastify__toast-container {
        z-index: 999999999999999999999999;

        .Toastify__toast-theme--light {
            border-radius: 0 !important;
            font-family: ${title};
            text-transform: capitalize;
        }
    }

    .mobile-menu {
        #fb-root, .fb_reset {
            display: none !important;
            opacity: 0 !important;
            visibility: hidden !important;
        }
    }

    .slick-slide {
        -webkit-transform: translate3d(0, 0, 0);
    }

    .reveal, .revealFast {
        overflow: hidden;
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;

        img {
            transform-origin: left;
        }

        .global-image {
            background: transparent;
        }
    }

    .reveal-right {
        overflow: hidden;
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        border-radius: 10px;

        img {
            transform-origin: right;
        }

        .global-image {
            background: transparent;
        }
    }

    //calender
    ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
        border-radius: 0 !important;
    }

    //video modal 
    .modal-video-close-btn:before, .modal-video-close-btn:after {
        background-color: ${hover};
    }

    .big-preloader {
        position: fixed;
        background-color: ${text};
        width: 100vw;
        z-index: 999999999;
        overflow: hidden;
        //opacity: 0;
        //height: 100vh;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .pre-loader__img {
            height: 100px;
            width: 100px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgb(36, 24, 67);
            z-index: 99999999;

            margin: auto;
            opacity: 0;

            img {
                display: block;
            }

            .top {
                height: 8px;
            }

            .bottom {
                height: 30px;
                margin-top: 5px;
                animation: flip 1s linear infinite;
            }

            @keyframes flip {
                0% {
                    transform: rotateY(0deg)
                }
                100% {
                    transform: rotateY(180deg)
                }
            }
        }


        &.big-preloader {

        }
    }

    //------------------------animation
    //text animation 
    .split-parent {
        overflow: hidden;
    }

    .split-child {
        overflow: hidden;
    }

    .reveal {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            transform-origin: left;
        }

        .global-image {
            background: transparent;
        }
    }


    //page transition
    .page-transition {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #2F374B;
        z-index: 999999999;
        display: none;
        //opacity: 1;
        //display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            height: 80px;
            opacity: 1;
        }
    }


    //menu fixed
    .scroll-down .menu-desktop {
        transform: translate3d(0, -100%, 0);
    }

    .scroll-up .menu-desktop {
        background-color: #fff;

        .menu-desktop__hamburger__lines {
            &__inner .line {
                background-color: #191818;
            }

            p {
                color: #191818;
            }
        }

        .menu-desktop__hamburger .controller svg {
            path {
                fill: #191818;
                stroke: #191818;

            }


            line {
                stroke: #191818;
            }
        }

        .dc-btn a {
            color: #191818;

            &:hover {
                color: #191818 !important;
            }

            &:after, &:before {
                box-shadow: 0 0 0 1px #191818;
            }
        }
    }


    .scroll-down.main-menu {
        transform: translate3d(0, -100%, 0);
    }

    .scroll-up.main-menu {
        background-color: #FFFFFF;

        .border-container::after {
            content: '' !important;
            background: rgba(147, 148, 152, 0.5);
            display: block !important;
        }

        .main-menu__search {
            svg {
                path {
                    fill: ${text}
                }
            }
        }

        .dc-btn a {
            border-color: ${text};
            color: ${text};
        }

        .main-menu__menu__inner {
            #Rectangle_5469 {
                fill: ${softGray};
            }

            line {
                stroke: ${text}
            }
        }

        .main-menu__menu .short-menu li a {
            color: ${text};
        }

        .main-menu__logo {
            .sticky {
                display: block;
            }

            .normal {
                display: none;
            }

        }

        .border-container {
            &:after {
            }
        }
    }

    .news-details .main-menu {
        background-color: #FFFFFF;

        .border-container::after {
            content: '' !important;
            background: rgba(147, 148, 152, 0.5);
            display: block !important;
        }

        .main-menu__search {
            svg {
                path {
                    fill: ${text}
                }
            }
        }

        .dc-btn a {
            border-color: ${text};
            color: ${text};
        }

        .main-menu__menu__inner {
            #Rectangle_5469 {
                fill: ${softGray};
            }

            line {
                stroke: ${text}
            }
        }

        .main-menu__menu .short-menu li a {
            color: ${text};
        }

        .main-menu__logo {
            .sticky {
                display: block;
            }

            .normal {
                display: none;
            }

        }

        .border-container {
            &:after {
            }
        }
    }

    body.open_menu {
        .overlay {
            height: calc(100vh - 100px);
        }
    }

    @keyframes bounce {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(8px);
        }

    }





    //modal video 
    //video modal
    .modal-video {
        background-color: transparent;
        height: 100vh;
        z-index: 99999;

        .modal-dialog {
            height: 100vh;
            background-color: transparent;
            min-width: 100%;
            margin: 0;
        }

        .modal-body {
            height: 100vh !important;
            flex: unset !important;
        }

        .modal-content {
            background-color: transparent;

        }

        iframe {
            height: 60vh;
            width: 60vw;
            margin: auto;
            position: absolute;
            inset: 0;
        }

        .close-modal {
            position: absolute;
            top: 40px;
            right: 30px;
            height: 30px;
            cursor: pointer;
            z-index: 99;
        }

        @media (max-width: 768px) {
            .modal-content {
                //padding: 0 20px;

                iframe {
                    width: 90vw;
                    height: 60vh;
                }

                .close-modal {
                    top: 80px;
                    right: 20px;
                }
            }
        }
        @media (max-width: 550px) {
            .modal-content iframe {
                width: 90vw;
                height: 40vh;
            }
        }
    }


    /* CSS for the page loader */
    .page-loader  {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh; /* Height of the loading bar */
        background-color: black; /* Loader bar color */
        z-index: 999999999999;

        //
        //.container {
        //  position: absolute;
        //  left: 0;
        //  right: 0;
        //  bottom: 100px;
        //
        //}
    }

    .loader-bar {
        height: 100%;
        width: 100%;
        padding-top: 60px;
        transition: width 0.2s cubic-bezier(.54, .32, .05, .96); /* Adjust the transition duration as needed */
        background-color: black; /* Loader bar color */
        color: #fff; /* Text color */
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        flex-direction: column;

        .bar {
            width: 0;
            background: white;
            height: 2px;
            top: 0;
            left: 0;
            right: 0;
            content: "";
            position: absolute;
            //transition: 0.8s all ease-in-out;

        }

        &:after {
            width: 0;
            background: white;
            height: 2px;
            top: 0;
            left: 0;
            right: 0;
            content: "";
            position: absolute;
        }
    }

    #smooth-content {
        @media (min-width: 767px) {
            will-change: transform;
        }

    }

    //modal video
    .modal-video {
        /* Hide scrollbar for Chrome, Safari and Opera */
        overscroll-behavior: none;

        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */


        &.stop-scroll {
            //overflow: hidden;
        }
    }


    //career modal 
    .popup-version-one.CareerModal {
        z-index: 9999;
        overflow-x: hidden;
        padding-left: 0 !important;
        padding: 0;
        overflow: hidden;

        .lock-svg {
            position: absolute;
            right: 0;
            top: 0;
        }

        .main_scroll {
            overflow-y: scroll;
        }

        .modal-title-wrapper {
            margin-bottom: 90px;
        }

        .modal-body {
            height: 100%;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .container {
                margin: 0;
            }
        }

        .for-close {
            position: absolute;
            right: 120px;
            top: 50px;
            cursor: pointer;
            @media (max-width: 767px) {
                right: 30px;
            }
        }

        .buttons-wrapper {
            background: rgb(172, 131, 94);
            padding: 0px;
            border-radius: 50px;
            height: 70px;
            align-items: center;
            justify-content: center;
            display: inline-flex;

            a {
                display: flex;
                width: 100%;
                justify-content: space-around;
                align-items: center;
                padding: 0 40px;

                .icon {
                    margin-right: 15px;
                }

                .text {
                    p:first-child {
                        color: rgba(241, 240, 238, 0.5);
                        font-size: 1rem; /* 15px / 15px = 1rem */
                        font-weight: 600;
                        line-height: 1.3333rem; /* 20px / 15px = 1.3333rem */
                        letter-spacing: -0.05rem;
                    }

                    p:last-child {
                        color: #181D24;
                        font-size: 1.3333rem; /* 20px / 15px = 1.3333rem */
                        font-weight: 300;
                        line-height: 1.8667rem; /* 28px / 15px = 1.8667rem */

                    }
                }
            }
        }

        .form_wrapper {
            height: 100%;
            position: relative;
            // overview_bg


            .left-col {
                div {
                    height: 100%;
                }
            }


            form {
                display: flex;
                height: 100%;
                flex-direction: column;
                //justify-content: space-between;

                .row {
                    margin-bottom: 40px;
                }
            }

            .textarea {
                min-height: 120px;
            }

            .title {
                font-size: 24px;
                font-weight: 4000;
                line-height: 28px;
                color: #F9F9F9;
                margin: 0 0 40px;
            }

            .form-group {
                margin-bottom: 40px;

                &:last-child {
                    margin-bottom: 0;
                }

                svg {
                    display: block;
                }

                .form-control {
                    box-shadow: none;
                    outline: 0;
                    border-radius: 0;
                    background: transparent;
                    height: auto;
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    border-color: transparent;
                    padding: 0 0 8px;
                    color: #040504;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;

                    svg {
                        display: block;
                    }

                    &::placeholder {
                        color: rgba(4,5,4,0.4);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
            }

        }

        .modal-dialog {
            max-width: 100%;
        }

        .modal-dialog {
            margin: 0 0 0 auto;
            width: 100%;
            border-radius: 0;

            .container {
                max-width: 90%;
            }

            .modal-content {
                border-radius: 0;
                background: transparent;

                .noise {
                    position: absolute;
                    inset: 0;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                    background: #1A181B;

                    &:after {
                        content: '';
                        background-image: url('/images/static/background-noise.png');
                        background-blend-mode: multiply;
                        //animation: Noise_grain 5s infinite linear;
                        //width: calc(100% + 500px);
                        //height: calc(100% + 500px);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        inset: 0;
                        opacity: 0.2;
                    }

                    &:after {
                        //animation: Noise_grain 5s infinite linear;
                    }


                }

                .modal-close {
                    height: auto;
                    width: auto;
                    border-radius: 0;
                    right: 30px;
                }

                .modal-data__content {
                    border: none;
                }

                .modal-main-content {
                    height: calc(100vh - 80px);
                    overflow-y: scroll;
                    padding-bottom: 90px !important;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    /* Hide scrollbar for IE, Edge and Firefox */
                    -ms-overflow-style: none; /* IE and Edge */
                    scrollbar-width: none; /* Firefox */

                    .profile-info {
                        h5 {
                            color: #F1EEE9;
                            font-size: 15px;
                            font-weight: 500;
                            text-transform: uppercase;
                            margin-bottom: 0;
                            margin-top: 20px;
                        }

                        p {
                            color: #7E7576;
                            font-size: 15px;
                            font-weight: 500;
                            text-transform: uppercase;
                            margin: 0;
                        }
                    }

                    .profile-details {
                        margin-top: 40px;

                        h2 {
                            color: #F1EEE9;
                            margin-bottom: 35px;
                        }

                        p {
                            color: #F1EEE9;
                            font-size: 15px;
                            font-weight: 300;
                            margin: 0;
                        }
                    }
                }
            }

            .modal-content-left {
                h5 {
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 32px;
                    color: #F1EEE9;
                    text-transform: uppercase;
                    padding-bottom: 40px;
                    border-bottom: 1px solid rgba(241, 238, 233, 0.2);
                }

                .inner-content {
                    margin-top: 40px;

                    h6 {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 32px;
                        color: #F1EEE9;
                        margin-bottom: 40px;
                    }

                    p {
                        color: #F1EEE9;
                        font-size: 15px;
                        font-weight: 300;
                    }

                    ul {
                        li {
                            font-size: 15px;
                            font-weight: 300;
                            color: #F1EEE9;
                            position: relative;
                            padding: 0px 0px 20px 40px;
                            z-index: 50;
                            position: relative;
                            counter-increment: count 1;
                            border-bottom: 1px solid rgba(241, 238, 233, 0.2);
                            margin-bottom: 20px;

                            &:before {
                                content: counter(count, decimal);
                                position: absolute;
                                height: 18px;
                                width: 18px;
                                margin: 0px;
                                font-size: 12px;
                                line-height: 16px;
                                font-weight: 500;
                                border: 1px #F1EEE9;
                                background-color: #F1EEE9;
                                border-radius: 50%;
                                color: #26201E;
                                top: 2px;
                                left: 0px;
                                display: flex;
                                -webkit-box-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                justify-content: center;
                            }

                            &:last-child {
                                border: none;
                            }
                        }
                    }
                }
            }

            .modal-data {
                display: flex !important;
                height: calc(100vh - 90px);
                overflow-y: scroll;


                &::-webkit-scrollbar {
                    display: none;
                }

                /* Hide scrollbar for IE, Edge and Firefox */
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }


            .form-wrapper-all {
                .form-control {
                    margin-bottom: 40px;
                    background: transparent;
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    border-bottom: 1px solid rgba(241, 238, 233, 0.5);
                    padding-left: 0;
                    padding-bottom: 15px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 24px;
                    color: rgba(241, 238, 233, 1);
                }


                .formContact {


                    input {
                        &:not(.react-select__input input) {
                            line-height: 20px;
                            border-radius: 0;
                            height: 44px;
                            border-color: rgba(255, 255, 255, 0.5);
                        }

                        padding-left: 0;
                        padding-right: 0;

                        &::placeholder {
                            color: rgba(241, 238, 233, 0.5);
                        }
                    }

                    .react-select__menu {
                        z-index: 9;
                    }

                    .react-select__control--menu-is-open {

                    }

                    .react-select__indicators {
                        display: block;

                        .react-select__indicator {
                            padding-right: 0;
                            padding-left: 0;
                            color: white !important;
                        }
                    }

                    .react-select__value-container {
                        padding: 0;
                        display: block;
                    }

                    .react-select__single-value {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        color: rgba(255, 255, 255, 1);
                    }

                    textarea {
                        border-radius: 0;
                        padding-left: 0;
                        padding-right: 0;
                        min-height: 100px;
                    }

                    .form-select {
                        .react-select__indicator-separator {
                            display: none;
                        }

                        .react-select__control {
                            //height: 48px;
                            border-color: rgba(255, 255, 255, 0.5);
                            box-shadow: none;
                            border-left: none;
                            border-top: none;
                            border-right: none;
                            background: transparent;
                            height: 30px;
                            margin-bottom: 40px;
                        }

                        .react-select__placeholder {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 24px;
                            color: rgba(255, 255, 255, 0.5);
                        }
                    }

                }


                .max_size {
                    font-size: 10px;
                    font-weight: 300;
                    line-height: 20px;
                    color: rgba(34, 34, 34, 0.8);
                    margin: 5px 0 0;
                }

                .gph_upload {
                    position: relative;
                    cursor: pointer;
                    border: none !important;
                    border-radius: 25px !important;
                    overflow: hidden !important;

                    &.hide {
                        &:before {
                            opacity: 0;
                        }
                    }

                    &:after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        content: attr(text);
                        border: 1px #FFFFFF;
                        border-radius: 25px;
                        border-style: dashed;
                        background: #3C3C3B;
                        z-index: 1;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 500;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: 0.7s all cubic-bezier(.54, .32, .05, .96);
                        padding-left: 20px;
                    }

                    &:before {
                        content: '';
                        background-image: url('images/static/attach.svg');
                        width: 20px;
                        height: 20px;
                        background-repeat: no-repeat;
                        background-size: contain;
                        position: absolute;
                        z-index: 11;
                        left: 25%;
                        top: 10px;
                    }

                    @media (min-width: 1024px) and (max-width: 1280px) {
                        &:after {
                            padding-left: 20px;
                        }

                        &:before {
                            left: 23%;
                        }
                    }
                    @media (min-width: 1536px) {
                        &:before {
                            left: 30%;
                        }
                    }

                    &:hover {
                        &:after {
                            border: 1px ${hover};
                            border-radius: 25px;
                            background: ${hover};
                        }
                    }
                }

                .has-error {
                    border-color: #EE1B24 !important;
                }
            }


        }


        @media (max-width: 992px) {
            .modal-body {
                justify-content: flex-start;
                padding-top: 110px;
            }

            .form_wrapper .form-group {
                margin-bottom: 0;
            }

            .main_scroll {
                overflow-y: scroll;
            }

            .buttons-wrapper {
                width: 300px;
                margin-bottom: 80px;
                margin-top: 40px;
            }

            .col-md-8.offset-md-2 {
                .d-flex.justify-content-between {
                    flex-direction: column;
                }
            }
        }

        @media (max-width: 767px) {
            .form_wrapper {
                form {
                    justify-content: unset;

                    .row {
                        margin-bottom: 30px;
                    }

                    .form-group {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }


    //fixed-element element
    .fixed-element {
        position: fixed;
        text-align: center;
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: 70px;
        z-index: 9999;
        display: inline-block;

        @media (max-width: 767px) {
            left: unset;
            transform: unset;
            right: 15px;
            &.request-for-quote {
                bottom: 140px;
                left: unset;
                transform: unset;
                right: 15px;
            }

            &.request-for-quoteOK {
                bottom: 140px;
                left: unset;
                transform: unset;
                right: 15px;
            }

            &.share {
                bottom: 140px;
                left: unset;
                transform: unset;
                right: 15px;
            }
        }

        .share_wrapper {
            display: flex;
            align-items: flex-start;
            background: #181D24;
            padding: 13px 35px;
            border-radius: 30px;

            .meta-font {
                margin-right: 7px;
                line-height: 19px;
                color: #818181;
            }

            svg {
                #Path_174, #Path_173 {
                    stroke: #1A181B;
                    fill: transparent;
                }

                path {
                    fill: #1A181B;
                }
            }

            .social-lists {
                display: flex;
                gap: 7px;
                align-items: flex-start;

                &.open {
                    opacity: 1;
                    visibility: visible;
                    height: 100%;
                }

                transition: 0.7s all cubic-bezier(.74, 0, .24, .99);
                flex-direction: row;

                .react-share__ShareButton {
                    margin-bottom: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }


    .pagination {
        align-items: center;
        justify-content: center;
        gap: 12px;

        li.active a {
            color: ${hover} !important
        }

        a {
            color: inherit !important;
            text-transåform: capitalize;
        }
    }


    //modal

.form-modal{

    
    @media(min-width: 1024px){
        .modal-body .modal-data{
            height: auto !important;
        }

        .form_wrapper form{
            justify-content: space-between !important;
        }

        .row .form-group{
            margin-bottom: 0 !important;
        }
        .single_image_image_wrp{
            height: auto !important;
            padding-top: 100vh !important;
        }
        .list_with_form{
            height: auto !important;
        }

    }
    @media(max-width: 1440px) and (min-width: 1024px){
        .form_wrapper{
            padding: 60px 70px 60px 60px !important;

            .textarea {
                min-height: 30px !important;
                max-height: 30px !important;
            }
        }
        .single_image_image .content_wrap .single_image_image_wrp .single_content_wrp{
            padding: 60px 0px 60px 60px !important;
        }
    }
    
    @media(max-width: 767px){
        .container-fluid{
            overflow: hidden;
        }
    }
}
    
    //modal for bod version 1
    .modal-backdrop {
        //background-color: #fff;
        opacity: 1 !important;
        z-index: 999;
        background-color: rgba(34, 31, 31, 0.9);
        min-width: 100%;
    }

    .modal {
        height: 100vh;
        overflow: hidden !important;
        z-index: 9999999;
     
        .close-icon-modal{
            position: absolute;
            right: 30px;
            z-index: 99;
            display: inline-block;
            height: 50px;
            max-width: 50px !important;
            width: 50px !important;
            .CloseIconSearchSvg{
                border: 1px solid ${text};
                
            }
            svg{
                line{
                    stroke: ${text};
                }    
                #Base{
                    stroke: none;
                }
            }
        }
        .modal-body{
            .container-fluid, .container{
                max-width: 100% !important;
                padding: 0 !important;
            }
        }
     

        .dc-btn {
            #Contact_Us {
                transform: translate(10px, 15px);
            }
        }

        #Ellipse_18 {
            opacity: 1;
        }

        #Ellipse_19 {
            stroke: ${hover}
        }

        .modal-data__content {
            border-top: 1px solid rgba(60, 60, 59, 0.3);
            padding-top: 40px;
            padding-left: 0;
        }

        &.modal {
            .modal-close {

            }

            .modal-body {
                //padding-top: 50px;
            }
        }

        .modal-body {
            padding: 0;
        }

        .modal-content {
            border: 0;
        }

        .modal-body {
            .modal-close {
                position: relative;
                height: 45px;
                width: 45px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                //top: -30px;
                right: 0px;
                top: 30px;
                overflow: hidden;


                svg {
                    #Ellipse_19 {
                        transition: 0.7s all ease;
                    }
                }

                &:hover {
                    svg {
                        #Ellipse_19 {
                            opacity: 1;
                            stroke-dasharray: 142px;
                        }
                    }
                }
            }

            .hover:after {
                border-radius: 50%;
            }

            .for-close {

                align-items: flex-end;
                justify-content: flex-end;

            }

            .modal-data {
                width: 100%;
                display: flex !important;
                flex-direction: row;
                padding-left: 0;
                height: 100vh;
                overflow-y: scroll;

                /* width */

                ::-webkit-scrollbar {
                    width: 5px;
                    display: none;
                }

                /* Track */

                ::-webkit-scrollbar-track {
                    background: transparent;
                    display: none;

                }

                /* Handle */

                ::-webkit-scrollbar-thumb {
                    background: ${gray};
                    display: none;

                }

                /* Handle on hover */

                ::-webkit-scrollbar-thumb:hover {
                    //background: transparent;
                }
                &__img {

                    img {
                        object-fit: cover;
                        width: 100%;
                        margin-bottom: 20px;
                    }

                    h4 {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 28px;
                    }

                    p {
                        color: rgba(60, 60, 59, 0.5);
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 32px;
                    }
                }

                &__content {
                    h4 {
                        font-size: 26px;
                        line-height: 32px;
                        font-weight: bold;
                        margin-bottom: 20px;
                    }

                    ul {

                        padding-bottom: 20px;
                        border-bottom: 1px solid #221F1F;
                        margin-bottom: 40px;

                        li {
                            position: relative;
                            padding-left: 25px;
                            font-size: 16px;
                            line-height: 22px;
                            margin-bottom: 10px;

                            &:after {
                                content: '';
                                height: 10px;
                                width: 10px;
                                background-color: ${hover};
                                border-radius: 50%;
                                left: 0;
                                top: 7px;
                                //bottom: 0;
                                //margin: auto;
                                position: absolute;
                            }
                        }

                    }

                    p {
                        font-size: 16px;
                        line-height: 22px;
                        margin-bottom: 20px;

                        &:nth-last-child(1) {
                            margin-bottom: 0;
                        }
                    }
                }

                .scroll-div {
                    overflow: hidden;
                    padding-right: 10px;
                    padding-bottom: 10px;
                }
            }
        }

        .modal-dialog {
            max-width: unset;
            margin: 1.75rem auto;
            padding: 0;
        }

        .simplebar-track {
            right: -12px;
        }

        .simplebar-track.simplebar-vertical {
            width: 6px;
        }
    }


    @media (max-width: 767px) {
        &.modal {
            .modal-close {
                right: 15px !important;
                top: 20px !important;
            }

            .modal-body {
                //padding-top: 50px;
            }
        }

        .modal {
            height: 100% !important;
            overflow-y: auto !important;
            min-height: 100%;

            .col-sm-7 {
                margin: 0;
            }
        }

        .modal-data__img {
            min-width: 100%;

            img {
                max-width: 100%;
            }
        }

        .modal-data__content {
            min-width: 100%;
        }

        .modal-dialog {
            width: 100%;
            margin: 0;
        }

        .modal-content {
            //padding: 50px 0;
            padding: 0;
            overflow: hidden;
            height: 100vh;
        }

        .modal-data__content {
            padding-bottom: 60px;
        }

        .modal-body {
            flex: 0;
            padding: 0;

            .modal-data {
                flex-wrap: wrap;
            }

            .modal-close {
                right: 15px;
                top: -10px;
            }
        }


    }


    //modal video
    .modal-video {
        /* Hide scrollbar for Chrome, Safari and Opera */
        overscroll-behavior: none;

        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */


        &.stop-scroll {
            //overflow: hidden;
        }
    }


    //modal for team popup 
    .popup-version-one {
        padding-left: 0 !important;
        background: ${gray};

        .modal-dialog {
            margin: 0 0 0 auto;
            width: 100%;
            border-radius: 0;

            .modal-content {
                border: 0px;
                border-radius: 0;
                background: transparent;


                .modal-close {
                    height: auto;
                    width: auto;
                    border-radius: 0;
                    right: 15px;
                }

                .modal-data__content {
                    border: none;
                }

                .modal-main-content {
                    height: calc(100vh - 160px);
                    overflow-y: scroll;
                    padding-bottom: 90px !important;
                    margin-top: 80px;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    /* Hide scrollbar for IE, Edge and Firefox */
                    -ms-overflow-style: none; /* IE and Edge */
                    scrollbar-width: none; /* Firefox */

                    .profile-info {
                        padding-bottom: 40px;

                        .img-wrp {
                            padding-top: calc(400 / 310 * 100%);
                            position: relative;
                            overflow: hidden;
                        }

                        h3 {
                            color: ${white};
                            margin: 40px 0 0;

                        }

                        p {
                            color: ${white};
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;
                            margin: 0 0 20px;
                        }
                    }

                    .profile-details {
                        h2 {
                            color: #F1EEE9;
                            margin-bottom: 35px;
                        }

                        p {
                            color: ${white};
                            font-size: 18px;
                            font-weight: 500;
                        }
                    }

                    @media (max-width: 992px) {
                        display: block !important;
                        .col-sm-4, .col-sm-8 {
                            flex: 0 0 100%;
                            max-width: 100%;
                        }

                        .profile-info {
                            margin-top: 40px;

                            .social {
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }
    }

    //responsive for team popup
    @media (max-width: 767px) {
        .popup-version-one {
            .modal-dialog {
                width: 100%;

                .modal-content {
                    .modal-main-content {
                        padding-left: 15px !important;
                        padding-right: 15px !important;

                        .profile-info {
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .popup-version-one .modal-dialog .modal-content .modal-main-content .profile-info .img-wrp img {
            height: 100%;
        }
    }


    //career modal 
    .popup-version-one.CareerModal {
        padding: 0;
        overflow: hidden;

        .width-fit {
            display: flex;
        }

        .modal-dialog {

            .modal-content {
                border-radius: 0;
                background: transparent;


                .modal-close {
                    height: auto;
                    width: auto;
                    border-radius: 0;
                    right: 30px;
                }

                .modal-data__content {
                    border: none;
                }

                .modal-main-content {
                    height: calc(100vh - 80px);
                    overflow-y: scroll;
                    padding-bottom: 90px !important;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    /* Hide scrollbar for IE, Edge and Firefox */
                    -ms-overflow-style: none; /* IE and Edge */
                    scrollbar-width: none; /* Firefox */

                    .profile-info {
                        h5 {
                            color: #F1EEE9;
                            font-size: 15px;
                            font-weight: 500;
                            text-transform: uppercase;
                            margin-bottom: 0;
                            margin-top: 20px;
                        }

                        p {
                            color: #7E7576;
                            font-size: 15px;
                            font-weight: 500;
                            text-transform: uppercase;
                            margin: 0;
                        }
                    }

                    .profile-details {
                        margin-top: 40px;

                        p {
                            color: #F1EEE9;
                            font-size: 15px;
                            font-weight: 300;
                            margin: 0;
                        }
                    }
                }
            }

            .modal-content-left {
                h5 {
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 32px;
                    color: #F1EEE9;
                    text-transform: uppercase;
                    padding-bottom: 40px;
                    border-bottom: 1px solid rgba(241, 238, 233, 0.2);
                }

                .inner-content {
                    margin-top: 40px;

                    h6 {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 32px;
                        color: #F1EEE9;
                        margin-bottom: 40px;
                    }

                    p {
                        color: #F1EEE9;
                        font-size: 15px;
                        font-weight: 300;
                    }

                    ul {
                        li {
                            font-size: 15px;
                            font-weight: 300;
                            color: #F1EEE9;
                            position: relative;
                            padding: 0px 0px 20px 40px;
                            z-index: 50;
                            position: relative;
                            counter-increment: count 1;
                            border-bottom: 1px solid rgba(241, 238, 233, 0.2);
                            margin-bottom: 20px;

                            &:before {
                                content: counter(count, decimal);
                                position: absolute;
                                height: 18px;
                                width: 18px;
                                margin: 0px;
                                font-size: 12px;
                                line-height: 16px;
                                font-weight: 500;
                                border: 1px #F1EEE9;
                                background-color: #F1EEE9;
                                border-radius: 50%;
                                color: #26201E;
                                top: 2px;
                                left: 0px;
                                display: flex;
                                -webkit-box-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                justify-content: center;
                            }

                            &:last-child {
                                border: none;
                            }
                        }
                    }
                }
            }

            .modal-data {
                display: flex !important;
                height: calc(100vh - 90px);
                overflow-y: scroll;


                &::-webkit-scrollbar {
                    display: none;
                }

                /* Hide scrollbar for IE, Edge and Firefox */
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }

            h5 {
                font-size: 24px;
                font-weight: 500;
                line-height: 32px;
                color: #F1EEE9;
                text-transform: uppercase;
                padding-bottom: 40px;
            }

            .form-wrapper-all {


                .form-control {
                    margin-bottom: 40px;
                    background: transparent;
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    border-bottom: 1px solid rgba(241, 238, 233, 0.5);
                    padding-left: 0;
                    padding-bottom: 15px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 24px;
                    color: rgba(241, 238, 233, 1);
                }


                .formContact {


                    input {
                        &:not(.react-select__input input) {
                            line-height: 20px;
                            border-radius: 0;
                            height: 44px;
                            border-color: rgba(255, 255, 255, 0.5);
                        }

                        padding-left: 0;
                        padding-right: 0;

                        &::placeholder {
                            color: rgba(241, 238, 233, 0.5);
                        }
                    }

                    .react-select__menu {
                        z-index: 9;
                    }

                    .react-select__control--menu-is-open {

                    }

                    .react-select__indicators {
                        display: block;

                        .react-select__indicator {
                            padding-right: 0;
                            padding-left: 0;
                            color: white !important;
                        }
                    }

                    .react-select__value-container {
                        padding: 0;
                        display: block;
                    }

                    .react-select__single-value {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        color: rgba(255, 255, 255, 1);
                    }

                    textarea {
                        border-radius: 0;
                        padding-left: 0;
                        padding-right: 0;
                        min-height: 100px;
                    }

                    .form-select {
                        .react-select__indicator-separator {
                            display: none;
                        }

                        .react-select__control {
                            //height: 48px;
                            border-color: rgba(255, 255, 255, 0.5);
                            box-shadow: none;
                            border-left: none;
                            border-top: none;
                            border-right: none;
                            background: transparent;
                            height: 30px;
                            margin-bottom: 40px;
                        }

                        .react-select__placeholder {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 24px;
                            color: rgba(255, 255, 255, 0.5);
                        }
                    }

                }


                .max_size {
                    font-size: 10px;
                    font-weight: 300;
                    line-height: 20px;
                    color: rgba(34, 34, 34, 0.8);
                    margin: 5px 0 0;
                }

                .gph_upload {
                    position: relative;
                    cursor: pointer;
                    border: none !important;
                    border-radius: 25px !important;
                    overflow: hidden !important;
                    height: 44px;

                    &.hide {
                        &:before {
                            //opacity: 0;
                        }
                    }

                    &:after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        content: attr(text);
                        border: 1px #FFFFFF;
                        border-radius: 25px;
                        border-style: solid;
                        background: ${text};
                        z-index: 1;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 500;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: 0.7s all ease;
                        padding-left: 20px;
                    }

                    &:before {
                        content: '';
                        background-image: url('/images/static/attach.svg');
                        width: 20px;
                        height: 20px;
                        background-repeat: no-repeat;
                        background-size: contain;
                        position: absolute;
                        z-index: 11;
                        left: 25%;
                        top: 10px;
                    }

                    @media (min-width: 1024px) and (max-width: 1280px) {
                        &:after {
                            padding-left: 20px;
                        }

                        &:before {
                            left: 23%;
                        }
                    }
                    @media (min-width: 1536px) {
                        &:before {
                            left: 30%;
                        }
                    }

                    &:hover {
                        &:after {
                            border: 1px ${hover};
                            border-radius: 25px;
                            background: ${hover};
                        }
                    }
                }

                .has-error {
                    border-color: #EE1B24 !important;
                }
            }


        }
    }


    .link {
        font-size: 14px;
        line-height: 25px;
        font-weight: 500;
        text-decoration: underline;
        color: ${text};
        transition: 0.7s all ease;

        &:hover {
            color: ${hover}
        }
    }


    //datepicker
    .rs-picker-default .rs-picker-toggle.rs-btn {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 50px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: flex-start !important;
        outline: none !important;
        border-radius: 10px !important;
        border: 1px solid rgba(147, 148, 152, 0.3) !important;
        max-width: 100% !important;
        width: 100% !important;
        padding-left: 20px !important;
    }

    .rs-picker-toggle-wrapper {
        width: 100% !important;
    }

    .rs-picker:not(.rs-picker-disabled):hover, .rs-picker.rs-picker-focused {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;

    }

    .rs-picker-toggle-placeholder {
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 21px !important;
        color: ${text} !important;
    }

    .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean, .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
        top: 13px !important;
        right: 12px !important;
        height: 16px !important;
        width: 16px !important;
    }

    .rs-picker-toggle-textbox {
        padding: 20px !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 21px !important;
        color: ${text} !important;

    }

    .split-up span{
        color: inherit !important;
    }

    .social-wrapper-main{
        .button_wrapper svg{
            top: -1px;
            left: -1px;
        }
    }


    .smooth-content{
        min-height: 100svh;
    }


    .blog-button{
        .slider-nav{
            ul{
                display: flex;
                gap: 15px;
            }
            li{
                height: 48px;
                width: 48px;
                background: #040504;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                svg{
                    position: relative;
                    z-index: 3;
                }
                &:after{
                    content: '';
                    position: absolute;
                    inset: 0;
                    background: ${hover};
                    border-radius: 50%;
                    transition: 0.7s all ease;
                    z-index: 2;
                    transform: translateY(100px);
                }
                &:hover{
                    &:after{
                        transform: translateY(0);
                    }
                }
            }
        }
    }
    
    body.search-open .scroll-down.desktop-menu{
        transform: translate3d(0px, 0px, 0px) !important;
    }
    .slider-nav {
        li{
            transition: 0.7s all ease;
        }
    }
    
    
    @media(max-width: 767px){
        #root{
            overflow: visible;
        }
    }



    body.menu-is-open {
        overflow: hidden !important;
    }






    /* total width */
    body::-webkit-scrollbar {
        background-color: transparent;
        width: 10px;
        position: fixed;
        opacity: 0;
    }

    /* background of the scrollbar except button or resizer */
    body::-webkit-scrollbar-track {
        background-color: transparent;
        position: fixed;
        opacity: 0;
    }

    /* scrollbar itself */
    body::-webkit-scrollbar-thumb {
        background-color: rgba(173, 173, 178, 0.93);
        border-radius: 8px;
        border: 2px solid #fff;
        opacity: 0;
    }

    /* set button(top and bottom of the scrollbar) */
    body::-webkit-scrollbar-button {
        display: none;
    }



    @media(min-width: 1536px){
      .popup-version-one  .form_wrapper .form-group{
            margin-bottom: 30px !important;
        }
    }
    
    
`;



